<template>
    <base-modal close @modal-close="closeModal" :show="show" size="sm">
        <template #modal-icon>
            <div class="flex">
                <base-feature-icon
                    variant="success"
                    name="line-icons:security:shield-tick"
                />
            </div>
        </template>
        <div class="flex flex-col">
            <h5 class="mb-2 text-lg font-medium text-gray-900">
                {{ $t('estimates.dispatch.convert.title') }}
            </h5>
            <p class="mb-2 text-sm font-normal text-gray-500">
                {{ $t('estimates.dispatch.convert.description') }}
            </p>
        </div>
        <template #modal-footer>
            <div class="flex justify-between gap-3">
                <base-button
                    outline
                    full-width
                    variant="default"
                    :disabled="loading"
                    @click="closeModal"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button full-width :loading="loading" @click="onSubmit">
                    {{ $t('general.confirm') }}
                </base-button>
            </div>
        </template>
    </base-modal>
</template>

<script setup>
const emit = defineEmits(['modal-close', 'on-submit'])

defineProps({
    show: {
        type: Boolean,
        required: true,
    },
    loading: {
        type: Boolean,
        default: false,
    },
})

const closeModal = () => {
    emit('modal-close')
}

const onSubmit = () => {
    emit('on-submit')
}
</script>
