export const LINE_ITEM_TYPES = {
    LINE_ITEM: 'line_item',
    DISCOUNT: 'discount',
    SURCHARGE: 'surcharge',
}

export const DEFAULT_COUNTRY_ISO = 'CY'

export const ESTIMATE_STATUS = {
    DRAFT: 'draft',
    EXPIRED: 'expired',
    INVOICED: 'invoiced',
    SENT: 'sent',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
}
