<template>
    <base-compact-list
        :items="estimates"
        :is-loading="isLoadingEstimates"
        :initial-selected-item="invoice"
        selectable
        @item-click="onSidebarItem"
        @load-more="onLoadMore"
        @item-check="onItemCheck"
        :initialize="initializeList"
    >
        <template #header="{ checkedItems }">
            <template v-if="checkedItems.length">
                <div class="flex items-center gap-2.5">
                    <base-button
                        v-if="$acl.can('update_estimates')"
                        variant="default"
                        outline
                        size="sm"
                        class="bg-white"
                        @click="isShowMarkAsSentModal = true"
                    >
                        <span class="font-normal">
                            {{ $t('estimates.dispatch.sidebar.markAsSentBtn') }}
                        </span>
                    </base-button>

                    <span class="text-sm text-gray-700">
                        {{
                            $t(
                                'estimates.dispatch.sidebar.selectedText',
                                checkedItems.length
                            )
                        }}
                    </span>
                </div>
            </template>

            <template v-else>
                <base-dropdown size="sm" variant="default" class="bg-white">
                    <template #btn-content>
                        {{ statusText }}

                        <base-icon
                            name="line-icons:arrows:chevron-down"
                            variant="inherit"
                        />
                    </template>

                    <template #default="{ toggleDropdown }">
                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange()
                                }
                            "
                        >
                            {{
                                $t(
                                    'estimates.dispatch.sidebar.filter.allEstimates'
                                )
                            }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(ESTIMATE_STATUS.DRAFT)
                                }
                            "
                        >
                            {{ $t('estimates.list.draft') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(ESTIMATE_STATUS.SENT)
                                }
                            "
                        >
                            {{ $t('estimates.list.sent') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(ESTIMATE_STATUS.ACCEPTED)
                                }
                            "
                        >
                            {{ $t('estimates.list.accepted') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(ESTIMATE_STATUS.DECLINED)
                                }
                            "
                        >
                            {{ $t('estimates.list.declined') }}
                        </base-dropdown-item>

                        <base-dropdown-item
                            @click="
                                () => {
                                    toggleDropdown()
                                    filterChange(ESTIMATE_STATUS.INVOICED)
                                }
                            "
                        >
                            {{ $t('estimates.list.invoiced') }}
                        </base-dropdown-item>
                    </template>
                </base-dropdown>

                <base-button
                    v-if="$acl.can('create_estimates')"
                    icon="line-icons:general:plus"
                    :label="$t('general.new')"
                    size="sm"
                    @click="goToEstimateCreate"
                />
            </template>
        </template>

        <template #item-content="{ item, clickItem }">
            <div class="w-full" @click="clickItem(item)">
                <div class="mb-1 flex w-full items-center justify-between">
                    <p class="text-sm text-gray-700">
                        {{
                            getContactName(
                                item?.sale_document?.contact?.contact
                            )
                        }}
                    </p>

                    <p class="text-sm text-gray-700">
                        {{
                            $filters.currency(
                                item?.sale_document?.amount_gross_total,
                                item?.sale_document?.currency?.iso
                            )
                        }}
                    </p>
                </div>

                <div class="flex w-full items-center justify-between">
                    <p class="text-xs">
                        <span class="text-primary-600">
                            {{ item?.sale_document?.number }}
                        </span>

                        <span class="px-2.5 text-gray-300">|</span>

                        <span class="text-gray-500">{{
                            $filters.dateHumanFormat(item?.date_of_estimate)
                        }}</span>
                    </p>

                    <partial-status
                        :status="
                            getEstimateStatus(
                                item.sale_document.status,
                                item.date_of_expiry
                            )
                        "
                        variant="small"
                    >
                        <template #text>{{
                            getEstimateStatus(
                                item.sale_document.status,
                                item.date_of_expiry
                            ).toUpperCase()
                        }}</template>
                    </partial-status>
                </div>
            </div>
        </template>
    </base-compact-list>

    <estimates-mark-as-sent-modal
        :items="checkedItems"
        :show="isShowMarkAsSentModal"
        @modal-close="isShowMarkAsSentModal = false"
        @modal-confirm="onMarkAsSent"
    />
</template>

<script setup>
import { isEqual, uniqBy } from 'lodash-es'
import { useFilters } from '@tenant/composables'
import { ESTIMATE_STATUS } from '@tenant/modules/tenant/estimates/utils/constants'
import { useInfiniteQuery } from '@tanstack/vue-query'
import { useEstimate } from '@tenant/modules/tenant/estimates/composables/use-estimate'
import { getContactName } from '@tenant/utils/helper'
const { getEstimateStatus } = useEstimate()

const props = defineProps({
    invoice: {
        type: Object,
        default: () => null,
    },
})

const route = useRoute()
const router = useRouter()

// INFINITE SCROLL ESTIMATE LIST
const { useApiSearch } = useApiFactory('sale-documents/estimates')
const { execute } = useApiSearch({}, false)

const {
    data: estimates,
    isFetching: isLoadingEstimates,
    fetchNextPage: fetchMoreAccounts,
    hasNextPage: hasMoreAccounts,
    refetch,
} = useInfiniteQuery({
    queryKey: ['estimates'],
    queryFn: ({ pageParam = 1 }) => {
        return execute({
            page: pageParam,
            limit: 20,
            ...route.query,
        })
    },
    getNextPageParam: ({ current_page, last_page }) => {
        return current_page < last_page ? current_page + 1 : undefined
    },
    select: (res) =>
        uniqBy(
            res?.pages?.flatMap((x) => x?.data),
            (x) => x?.id
        ),
})

const onLoadMore = () => {
    if (hasMoreAccounts.value) {
        fetchMoreAccounts()
    }
}

// END: INFINITE SCROLL INVOICE LIST

const onSidebarItem = (item) => {
    router.push({
        name: 'estimates.dispatch',
        params: { id: item.id },
        query: { ...route.query },
    })
}

const goToEstimateCreate = () => {
    router.push({ name: 'estimates.create' })
}

// FILTER ESTIMATES
const queries = {
    type: 'filter.saleDocument.status',
    date_of_expiry: 'filter.date_of_expiry',
}
const { filterValues, updateFilters } = useFilters(queries)
const status = ref(null)
watch(
    () => filterValues.value,
    (value, oldValue) => {
        if (isEqual(value, oldValue)) return
        refetch()
    }
)
watch(
    () => props.invoice?.sale_document?.status,
    () => {
        refetch()
    }
)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { type } = filterValues.value
    if (!type) {
        return
    }

    status.value = type
}

const filterChange = (updateStatus = null) => {
    const filters = {
        [queries.type]: updateStatus,
        [queries.date_of_expiry]: null,
    }

    status.value = updateStatus
    updateFilters(filters)
}

const { t } = useI18n()
const statusText = computed(() => {
    if (status.value === ESTIMATE_STATUS.SENT) {
        return t('estimates.list.sent')
    }

    if (status.value === ESTIMATE_STATUS.ACCEPTED) {
        return t('estimates.list.accepted')
    }

    if (status.value === ESTIMATE_STATUS.DECLINED) {
        return t('estimates.list.declined')
    }

    if (status.value === ESTIMATE_STATUS.DRAFT) {
        return t('estimates.list.draft')
    }

    if (status.value === ESTIMATE_STATUS.INVOICED) {
        return t('estimates.list.invoiced')
    }

    return t('estimates.dispatch.sidebar.filter.allEstimates')
})
// END: FILTER ESTIMATES

const checkedItems = ref([])
const onItemCheck = ({ checkedItems: items }) => {
    checkedItems.value = items
}
const initializeList = ref(false)

// MARK AS SENT
const emit = defineEmits(['data-update'])

const isShowMarkAsSentModal = ref(false)
const onMarkAsSent = async () => {
    isShowMarkAsSentModal.value = false
    initializeList.value = true

    emit('data-update', () => {
        initializeList.value = false
    })
}
// END: MARK AS SENT
</script>
