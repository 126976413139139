<template>
    <base-sticky-heading
        :title="$t('createEstimate.title')"
        class="px-6 py-3.5"
    >
        <template #right>
            <div class="flex gap-3">
                <base-button
                    outline
                    variant="default"
                    @click="onCancel"
                    :disabled="isSaving"
                >
                    {{ $t('general.cancel') }}
                </base-button>
                <base-button
                    outline
                    variant="default"
                    @click="onPreview"
                    icon="line-icons:general:search-lg"
                    :loading="isSaving"
                >
                    {{ $t('createEstimate.preview') }}
                </base-button>
                <base-button
                    variant="primary"
                    icon="line-icons:general:check"
                    @click="onProceed"
                    :loading="isSaving"
                >
                    {{ $t('createEstimate.proceedBtn') }}
                </base-button>
            </div>
        </template>

        <div class="p-6">
            <estimates-create-information />
            <estimates-create-header-content :default-content="headerContent" />
            <estimates-create-line-items />
            <estimates-create-footer-content :default-content="footerContent" />
            <estimates-create-more-options />
            <estimates-create-amount />
        </div>
    </base-sticky-heading>

    <transaction-book-close-modal
        :code="code"
        :date="parameters?.date"
        @modal-close="code = null"
    />
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { ESTIMATE_STATUS } from '@tenant/modules/tenant/estimates/utils/constants'
import { useTextTemplate } from '@tenant/composables/apis/use-text-template'

const { t } = useI18n()
const router = useRouter()
const { setErrors, handleSubmit } = useForm()

useHead({
    title: t('createEstimate.title'),
})

const onCancel = () => {
    router.push({ name: 'estimates.list' })
}

const onPreview = handleSubmit(async ({ contact: _, ...values }) => {
    const data = await saveEstimate(values)

    if (data?.id) {
        await router.push({
            name: 'estimates.dispatch',
            params: { id: data.id },
        })
    }
})

const onProceed = handleSubmit(async ({ contact: _, ...values }) => {
    const data = await saveEstimate(values)

    if (data?.id) {
        await router.push({
            name: 'estimates.dispatch',
            params: { id: data.id },
        })
    }
})

const store = useStore()
const currentUser = computed(() => store.getters.user)
const { useApiCreate } = useApiFactory('sale-documents/estimates')
const { execute, loading: isSaving, code, parameters } = useApiCreate()
const { defaultCurrency } = useCurrencies()

const saveEstimate = async (values, status = ESTIMATE_STATUS.DRAFT) => {
    try {
        return await execute({
            ...values,
            currency_id: defaultCurrency.value.value,
            user_id: currentUser.value.id,
            status,
        })
    } catch ({ errors }) {
        setErrors(errors)
    }
}

// Get default text template for estimate
const { getDefaultTextTemplate } = useTextTemplate()
const textTemplates = ref(null)
onMounted(async () => {
    textTemplates.value = await getDefaultTextTemplate('document', 'estimates')
})

const footerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'footer_content'
        )?.content ?? ''
    )
})

const headerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'header_content'
        )?.content ?? ''
    )
})
</script>
