<template>
    <div class="relative border-t pt-6">
        <!-- AC-234: Support multi currency -->
        <div class="mb-5 flex border-b text-xl">
            <div class="grow">{{ $t('createEstimate.netAmount') }}</div>
            <div>
                {{ $filters.currency(totalNetAmount, estimateCurrency) }}
            </div>
        </div>
        <div
            class="mb-5 flex border-b text-xl"
            v-for="(value, key) in vatAmounts"
            :key="key"
        >
            <div class="grow">{{ $t('createEstimate.vat', { n: key }) }}</div>
            <div>
                {{ $filters.currency(value, estimateCurrency) }}
            </div>
        </div>
        <div class="flex text-2xl font-bold">
            <div class="grow">{{ $t('createEstimate.total') }}</div>
            <div class="text-xl">
                {{ $filters.currency(totalAmount, estimateCurrency) }}
            </div>
        </div>
    </div>
</template>

<script setup>
import { CURRENCIES } from '@tenant/utils/constants'

const { fields } = useFieldArray('line_items')
const { value: amountNet } = useField('amount_net_total')
const { value: amountGross } = useField('amount_gross_total')
const { totalAmount, totalNetAmount, vatAmounts } = useTotalSummary(fields)

// Update `amount_net_total` and `amount_gross_total` fields based on computed values from `useEstimate`
watch(totalAmount, () => (amountGross.value = totalAmount.value), {
    immediate: true,
})
watch(totalNetAmount, () => (amountNet.value = totalNetAmount.value), {
    immediate: true,
})

const estimateCurrency = CURRENCIES.eur.iso
</script>
