<template>
    <base-context-menu>
        <base-context-menu-item
            v-if="status.isDraft() && $acl.can('update_estimates')"
            icon="line-icons:communication:mail-01"
            @click="onSendEstimate"
        >
            {{ $t('estimates.list.contextMenu.send') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="!status.isDraft()"
            icon="line-icons:general:download-01"
            @click="$emit('download-estimate')"
        >
            {{ $t('estimates.list.contextMenu.download') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="!status.isDraft()"
            icon="line-icons:general:eye"
            @click="onPreviewEstimate"
        >
            {{ $t('estimates.list.contextMenu.preview') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="$acl.can('update_estimates')"
            icon="line-icons:general:copy-01"
            @click="$emit('copy-estimate')"
        >
            {{ $t('estimates.list.contextMenu.copyEstimate') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="status.isDraft() && $acl.can('update_estimates')"
            icon="line-icons:files:file-attachment-04"
            @click="onEditDocument"
        >
            {{ $t('estimates.list.contextMenu.editDocument') }}
        </base-context-menu-item>

        <base-context-menu-item
            v-if="status.isDraft() && $acl.can('delete_estimates')"
            icon="line-icons:general:trash-01"
            @click="onDeleteEstimate"
        >
            {{ $t('general.delete') }}
        </base-context-menu-item>
    </base-context-menu>
</template>

<script setup>
import { createEntityStatus } from '@tenant/utils/entity-status'

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
})

const status = computed(() =>
    createEntityStatus(props.item.sale_document.status)
)
const emit = defineEmits([
    'delete-estimate',
    'download-estimate',
    'copy-estimate',
])

const router = useRouter()

const onSendEstimate = () => {
    router.push({
        name: 'estimates.dispatch',
        params: { id: props.item.id },
    })
}

const onEditDocument = () => {
    router.push({
        name: 'estimates.edit',
        params: { id: props.item.id },
    })
}

const onPreviewEstimate = () => {
    router.push({
        name: 'estimates.dispatch',
        params: { id: props.item.id },
    })
}

const onDeleteEstimate = () => {
    emit('delete-estimate', props.item.id)
}
</script>
