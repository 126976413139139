import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'estimates',
        path: '/estimates',
        redirect: {
            name: 'estimates.list',
        },
        children: [
            {
                name: 'estimates.list',
                path: '',
                component: () => import('./pages/estimates-list.vue'),
            },
            {
                name: 'estimates.create',
                path: 'create',
                component: () => import('./pages/estimates-create.vue'),
                meta: {
                    permission: 'create_estimates',
                },
            },
            {
                name: 'estimates.edit',
                path: ':id',
                component: () => import('./pages/estimates-edit.vue'),
                meta: {
                    permission: 'update_estimates',
                },
            },
            {
                name: 'estimates.dispatch',
                path: 'dispatch/:id',
                component: () => import('./pages/estimates-dispatch.vue'),
            },
        ],
    },
    {
        name: 'estimates.share',
        path: '/estimate-share/:tenantId/:hash',
        component: () => import('./pages/estimates-share.vue'),
        meta: { guard: GUARD.LINK },
    },
    {
        name: 'estimate.share-preview',
        path: '/estimate-share-preview/:tenantId/:hash',
        component: () => import('./pages/estimates-share.vue'),
        meta: { guard: GUARD.LINK },
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_estimates',
    },
}
