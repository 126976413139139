<template>
    <form-text-input
        class="grow"
        size="sm"
        icon-name="line-icons:general:search-lg"
        :model-value="filterValues.number"
        :placeholder="$t('estimates.list.searchByEstimateNumber')"
        @change="onChange($event.target.value)"
    />
</template>

<script setup>
import { useFilters } from '@tenant/composables'

const queries = {
    number: 'q',
}
const { filterValues, updateFilter } = useFilters(queries)

const onChange = (value) => updateFilter(queries.number, value)
</script>
