<template>
    <data-grid
        :data-source="estimates.data"
        :columns="columns"
        :loading="loadingSearch"
        :empty-text="$t('estimates.emptyText')"
        :empty-sub="$t('estimates.emptySubText')"
        identify-field="id"
        @row-click="redirectToDetail"
    >
        <template v-if="$slots['header']" #header>
            <slot name="header" />
        </template>

        <template v-if="$slots['filter']" #filter>
            <slot name="filter" />
        </template>

        <template #column-status="{ item }">
            <partial-status
                :status="
                    getEstimateStatus(
                        item.sale_document.status,
                        item.date_of_expiry
                    )
                "
                variant="small"
            >
                <template #text>
                    {{
                        getEstimateStatus(
                            item.sale_document.status,
                            item.date_of_expiry
                        ).toUpperCase()
                    }}
                </template>
            </partial-status>
        </template>

        <template #column-dueDate="{ item }">
            <span
                :class="{
                    'line-through':
                        item.sale_document.status === ESTIMATE_STATUS.INVOICED,
                    'text-danger-600': [ESTIMATE_STATUS.EXPIRED].includes(
                        getEstimateStatus(
                            item.sale_document.status,
                            item.date_of_expiry
                        )
                    ),
                }"
            >
                {{ $filters.dateShortFormat(item.date_of_expiry) }}
            </span>
        </template>

        <template #column-customer="{ item }">
            <div class="flex flex-col">
                <div
                    class="whitespace-nowrap text-sm font-normal text-gray-900"
                >
                    <slot name="title">{{
                        getContactName(item.sale_document.contact)
                    }}</slot>
                </div>
                <div
                    class="whitespace-nowrap text-sm font-normal text-gray-500"
                >
                    <slot name="description"
                        >{{ $t('estimates.list.number') }}
                        {{ item.estimate_header }}</slot
                    >
                </div>
            </div>
        </template>

        <template #column-date="{ item }">
            {{ $filters.dateShortFormat(item.date_of_estimate) }}
        </template>

        <template #column-number="{ item }">
            {{ item.sale_document.number }}
        </template>

        <template #column-amountNet="{ item }">
            {{
                $filters.currency(
                    item.sale_document.amount_net_total,
                    item.sale_document?.currency?.iso ?? estimateCurrency
                )
            }}
        </template>

        <template #column-amountGross="{ item }">
            {{
                $filters.currency(
                    item.sale_document.amount_gross_total,
                    item.sale_document?.currency?.iso ?? estimateCurrency
                )
            }}
        </template>

        <template #column-unpaidGross="{ item }">
            {{
                $filters.currency(
                    item.sale_document.unpaid_amount,
                    item.sale_document?.currency?.iso ?? estimateCurrency
                )
            }}
        </template>

        <template #column-action="{ item }">
            <estimates-context-menu
                :item="item"
                @delete-estimate="showDeleteModal = true"
                @download-estimate="downloadEstimate(item)"
                @copy-estimate="copyEstimate(item)"
            />

            <estimates-delete-modal
                :estimate-id="item.id"
                :loading="isDeleting"
                :show="showDeleteModal"
                @modal-close="showDeleteModal = false"
                @modal-confirm="onDeleteEstimate"
            />
        </template>

        <template #footer>
            <grid-pagination
                :total="estimates.last_page"
                :page="estimates.current_page"
            />
        </template>

        <template v-if="!hasFilter" #empty-actions>
            <slot name="empty-actions">
                <base-button
                    v-if="$acl.can('create_estimates')"
                    size="md"
                    variant="primary"
                    icon="line-icons:general:plus"
                    @click="redirectToCreate"
                >
                    {{ $t('estimates.list.add') }}
                </base-button>
            </slot>
        </template>
    </data-grid>
</template>

<script setup>
import {
    CONTACT_RELATIONSHIP_TYPES,
    CURRENCIES,
    DATE_SERVER_FORMAT,
} from '@tenant/utils/constants'
import { useEstimate } from '@tenant/modules/tenant/estimates/composables/use-estimate'
import { ESTIMATE_STATUS } from '@tenant/modules/tenant/estimates/utils/constants'

const { t } = useI18n()
const router = useRouter()

const emit = defineEmits(['estimate-total'])
const props = defineProps({
    params: {
        type: Object,
        default: () => ({}),
    },
})

const {
    entities: estimates,
    useApiSearch,
    useApiDelete,
} = useApiFactory('sale-documents/estimates')
const {
    execute: searchEstimate,
    loading: loadingSearch,
    executeRefreshSearch: refreshEstimates,
} = useApiSearch({ ...props.params }, true)

onMounted(async () => {
    await searchEstimate()
    emit('estimate-total', estimates.value.total)
})

const estimateCurrency = CURRENCIES.eur.iso

const columns = [
    {
        property: 'status',
        label: t('estimates.list.status'),
    },
    {
        property: 'dueDate',
        label: t('estimates.list.dateOfExpiry'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'number',
        label: t('estimates.list.number'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'customer',
        label: t('estimates.list.customer'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'date',
        label: t('estimates.list.date'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'amountNet',
        label: t('estimates.list.amountNet'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'amountGross',
        label: t('estimates.list.amountGross'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
    {
        property: 'unpaidGross',
        label: t('estimates.list.unpaidGross'),
        headerCellClass: 'hidden xl:table-cell',
        dataCellClass: 'hidden xl:table-cell',
    },
]

const redirectToCreate = () => {
    router.push({ name: 'estimates.create' })
}

const getContactName = (contact) => {
    if (!contact) return null

    return contact.type === CONTACT_RELATIONSHIP_TYPES.person
        ? `${contact.first_name} ${contact.last_name}`
        : contact.company
}

// DELETE ESTIMATE
const showDeleteModal = ref(false)
const isDeleting = ref(false)
const { execute: deleteEstimate } = useApiDelete()
const { errorNotify, successNotify } = useNotification()

const onDeleteEstimate = async (id) => {
    try {
        isDeleting.value = true
        await deleteEstimate(id)
        await refreshEstimates()
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    } finally {
        isDeleting.value = false
        showDeleteModal.value = false
    }
}
// END: DELETE ESTIMATE

// DOWNLOAD ESTIMATE
const { getEstimateStatus } = useEstimate()
const { execute } = useApi(
    '/api/sale-documents/estimates/{id}/generate-pdf',
    'POST'
)
const downloadEstimate = async (item) => {
    try {
        const { content } = await execute({
            params: {
                id: item.id,
            },
        })
        exportPDF(`${item.estimate_header}.pdf`, content)
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    }
}
// END: DOWNLOAD ESTIMATE

// PRE-FETCH CONTACTS FOR FILTER PANEL
import { QueryClient } from '@tanstack/vue-query'
import { useStore } from 'vuex'
import { cloneDeep, isNil, omitBy } from 'lodash-es'
import { dayjsFormat } from '@tenant/utils/day'
import { exportPDF } from '@tenant/utils/helper'
const { execute: executeSearchContacts } = useApiFactory(
    'contacts'
).useApiSearch({}, false)

const queryClient = new QueryClient()
queryClient.prefetchQuery({
    queryKey: ['contacts'],
    queryFn: () =>
        executeSearchContacts({
            queries: { page: 1, limit: 500 },
        }),
    staleTime: 60 * 1000,
})
// END: PRE-FETCH CONTACTS FOR FILTER PANEL

const store = useStore()
const currentUser = computed(() => store.getters.user)
const emitter = useEmitter()
const { useApiCreate } = useApiFactory('sale-documents/estimates')
const { execute: saveEstimate } = useApiCreate()
const { execute: generateNumber } = useApi(
    '/api/sequence-settings/generate-number/estimate',
    'GET'
)

// COPY ESTIMATE
const copyEstimate = async ({ ...item }) => {
    try {
        emitter.emit('set-loading', true)

        const { contact } = cloneDeep(item.sale_document)

        const { date_of_estimate, date_of_expiry } = cloneDeep(item)
        delete item.id

        if (item.sale_document.status !== ESTIMATE_STATUS.DRAFT) {
            item.sale_document.number = await generateNumber()
            item.estimate_header = t(
                'createEstimate.estimateHeaderPlaceholderValue',
                {
                    number: item.sale_document.number,
                }
            )
        }

        await saveEstimate({
            ...omitBy(item, isNil),
            contact_id: contact.contact_id,
            date_of_estimate: dayjsFormat(date_of_estimate, DATE_SERVER_FORMAT),
            date_of_expiry: dayjsFormat(date_of_expiry, DATE_SERVER_FORMAT),
            user_id: currentUser.value.id,
            status: ESTIMATE_STATUS.DRAFT,
        })
        await refreshEstimates()

        successNotify({
            title: t('estimates.general.copySuccess'),
        })
    } catch ({ hasErrors, errorMessage }) {
        if (hasErrors) {
            errorNotify({
                title: errorMessage,
            })
        }
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: COPY ESTIMATE

const route = useRoute()
const hasFilter = computed(() => route.query.q || route.query.filter)

const redirectToDetail = (id) => {
    router.push({ name: 'estimates.dispatch', params: { id } })
}
</script>
