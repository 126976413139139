const navigation = [
    {
        id: 'sas-estimates',
        path: 'estimates',
        label: 'estimates.sidebar.label',
        position: 20,
        parent: 'sas-sales-payment',
        permission: 'view_estimates',
    },
]

export default navigation
