import { ESTIMATE_STATUS } from '@tenant/modules/tenant/estimates/utils/constants'
import { dayDiff, now } from '@tenant/utils/day'

export const useEstimate = () => {
    const getEstimateStatus = (status, dateOfExpiry) => {
        if (
            status === ESTIMATE_STATUS.SENT &&
            dayDiff(dateOfExpiry, now()) < 0
        ) {
            return ESTIMATE_STATUS.EXPIRED
        }

        return status
    }

    return {
        getEstimateStatus,
    }
}
