<template>
    <div class="my-6">
        <base-section-divider :text="$t('createEstimate.customerInfoTitle')" />
        <div class="grid grid-cols-2 items-start gap-x-4">
            <div class="grid grid-cols-1 gap-y-5">
                <form-contact-select
                    v-model="contactId"
                    :error-message="errorContactId"
                    :label="$t('createEstimate.customerLabel')"
                    :placeholder="$t('createEstimate.customerPlaceholder')"
                    :input-display="getContactName(contact)"
                    @on-contact-changed="onContactChange"
                />

                <form-textarea
                    :label="$t('createEstimate.customerAddressLabel')"
                    :placeholder="
                        $t('createEstimate.customerAddressPlaceholder')
                    "
                    rows="6"
                    v-model="customerAddress"
                />

                <form-single-select
                    v-model="customerCountry"
                    :label="$t('createEstimate.customerCountryLabel')"
                    :options="countries"
                    :error-message="errorCustomerCountry"
                />
            </div>
            <div class="grid grid-cols-2 gap-x-3 gap-y-5">
                <form-text-input
                    :label="$t('createEstimate.estimateHeaderLabel')"
                    :placeholder="
                        $t('createEstimate.estimateHeaderPlaceholder')
                    "
                    v-model="estimateHeader"
                    :error-message="errorEstimateHeader"
                />
                <form-text-input
                    v-model="estimateNumber"
                    :label="$t('createEstimate.estimateNumberLabel')"
                    :placeholder="
                        $t('createEstimate.estimateNumberPlaceholder')
                    "
                    :error-message="errorEstimateNumber"
                    disabled
                />
                <form-date-picker
                    :label="$t('createEstimate.dateOfEstimateLabel')"
                    single
                    v-model="dateOfEstimate"
                    :error-message="errorDateOfEstimate"
                />
                <form-date-picker
                    :label="$t('createEstimate.dateOfExpiryLabel')"
                    single
                    v-model="dateOfExpiry"
                    :error-message="errorDateOfExpiry"
                />
                <form-text-input
                    :label="$t('createEstimate.estimateReferenceNoLabel')"
                    placeholder="CY1245234"
                    v-model="referenceNumber"
                    :error-message="errorReferenceNumber"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { dayDiff, dayjsFormat, now } from '@tenant/utils/day'
import {
    DATE_SERVER_FORMAT,
    DEFAULT_COUNTRY_ISO,
} from '@tenant/utils/constants'
import { getContactName } from '@tenant/utils/helper'

const { countries } = useCountries()
const route = useRoute()

// FORM VALUES
const { value: customerAddress } = useField('address')
const { value: customerCountry, errorMessage: errorCustomerCountry } =
    useField('country_id')
const { value: estimateHeader, errorMessage: errorEstimateHeader } =
    useField('estimate_header')
const { value: estimateNumber, errorMessage: errorEstimateNumber } =
    useField('number')
const { value: dateOfEstimate, errorMessage: errorDateOfEstimate } =
    useField('date_of_estimate')
const { value: dateOfExpiry, errorMessage: errorDateOfExpiry } =
    useField('date_of_expiry')
const { value: referenceNumber, errorMessage: errorReferenceNumber } =
    useField('reference')
const { value: contactId, errorMessage: errorContactId } =
    useField('contact_id')
const { value: contact } = useField('contact')
// END FORM VALUES

// CONTACTS
const { useApiGet } = useApiFactory('contacts')
const { execute: executeGet, result } = useApiGet()

const emitter = useEmitter()
onMounted(async () => {
    emitter.emit('set-loading', true)

    if (route.query.contact_id) {
        contactId.value = route.query.contact_id
        await executeGet(contactId.value)
        contact.value = result.value
    }

    await generateEstimateNumber()

    emitter.emit('set-loading', false)
})

const generateEstimateNumber = () => {
    const { execute } = useApi(
        '/api/sequence-settings/generate-number/estimate',
        'GET'
    )

    return execute().then((response) => (estimateNumber.value = response))
}

/**
 * Set default country to Cyprus
 * @returns {*}
 */
const setDefaultCountry = () =>
    (customerCountry.value = countries.value.find(
        (country) => country.iso === DEFAULT_COUNTRY_ISO
    )?.value)

const isContactChanged = ref(false)
const onContactChange = ({ item, id }) => {
    contactId.value = id
    contact.value = item
}

watch(
    contact,
    () => {
        if (!isContactChanged.value) return

        if (contact.value?.contact_addresses?.length > 0) {
            const { street, postcode, city, country_id } =
                contact.value.contact_addresses[0]
            if (street && postcode && city) {
                customerAddress.value = `${street}\n${postcode} ${city}`
            }

            if (country_id && !customerCountry.value) {
                customerCountry.value = country_id
            } else {
                setDefaultCountry()
            }
        } else {
            customerAddress.value = ''
            setDefaultCountry()
        }
    },
    {
        immediate: true,
    }
)
// END CONTACTS

// Auto-generate estimate number
const { t } = useI18n()
watch(estimateNumber, () => {
    estimateHeader.value = t('createEstimate.estimateHeaderPlaceholderValue', {
        number: estimateNumber.value,
    })
})

// Automatically set due date when date of estimate changed
watch(dateOfEstimate, (value) => {
    if (dayDiff(dateOfExpiry.value, value) <= 0) {
        dateOfExpiry.value = value
    }
})

// Set `Date of estimate` and `Date of delivery` default by today.
onMounted(() => {
    const today = dayjsFormat(now(), DATE_SERVER_FORMAT)
    dateOfEstimate.value = today
    dateOfExpiry.value = today
})

watch(
    countries,
    () => {
        if (!customerCountry.value) {
            setDefaultCountry()
        }
    },
    { immediate: true }
)
</script>
