<template>
    <div class="flex h-screen" v-if="estimate">
        <estimates-sidebar-list
            :invoice="estimate"
            @data-update="onDataUpdate"
        />
        <div class="grow">
            <base-heading
                :title="estimate?.estimate_header"
                class="mb-6 px-6 py-5"
            >
                <template #title>
                    <div class="flex items-center">
                        <strong class="mr-2 text-lg font-medium text-gray-900">
                            {{ estimate?.estimate_header }}
                        </strong>

                        <partial-status
                            :status="
                                getEstimateStatus(
                                    estimate.sale_document.status,
                                    estimate.date_of_expiry
                                )
                            "
                            variant="small"
                        >
                            <template #text>
                                {{
                                    getEstimateStatus(
                                        estimate.sale_document.status,
                                        estimate.date_of_expiry
                                    ).toUpperCase()
                                }}
                            </template>
                        </partial-status>
                    </div>
                </template>

                <template #right>
                    <div class="flex gap-3">
                        <template v-if="$acl.can('update_estimates')">
                            <base-button
                                v-if="
                                    ![
                                        ESTIMATE_STATUS.ACCEPTED,
                                        ESTIMATE_STATUS.INVOICED,
                                    ].includes(estimate?.sale_document?.status)
                                "
                                size="sm"
                                variant="default"
                                icon="line-icons:editor:pencil-01"
                                @click="editDraft"
                            >
                                {{ $t('estimates.dispatch.actions.edit') }}
                            </base-button>

                            <base-button
                                @click="sendEstimateModal = true"
                                size="sm"
                                variant="default"
                                icon="line-icons:communication:mail-01"
                            >
                                {{
                                    estimate.sale_document?.sent_at
                                        ? $t(
                                              'estimates.dispatch.actions.resend'
                                          )
                                        : $t('estimates.dispatch.actions.send')
                                }}
                            </base-button>
                        </template>

                        <base-button
                            @click="printDocument(base64PdfContent)"
                            size="sm"
                            variant="default"
                            icon="line-icons:media-&-devices:printer"
                        />

                        <base-button
                            v-if="
                                ![
                                    ESTIMATE_STATUS.DRAFT,
                                    ESTIMATE_STATUS.ACCEPTED,
                                    ESTIMATE_STATUS.INVOICED,
                                ].includes(estimate?.sale_document?.status)
                            "
                            size="sm"
                            icon="line-icons:files:file-plus-02"
                            :loading="isLoadingTextTemplates"
                            @click="openCovertInvoiceModal"
                        >
                            {{ $t('estimates.dispatch.actions.convert') }}
                        </base-button>

                        <base-context-menu>
                            <template #default="{ closeDropdown }">
                                <template v-if="$acl.can('update_estimates')">
                                    <base-context-menu-item
                                        v-if="!estimate?.sale_document?.sent_at"
                                        icon="line-icons:files:file-check-02"
                                    >
                                        {{
                                            $t(
                                                'estimates.dispatch.actions.markAsSent'
                                            )
                                        }}
                                    </base-context-menu-item>

                                    <template v-else>
                                        <base-context-menu-item
                                            v-if="
                                                ![
                                                    ESTIMATE_STATUS.ACCEPTED,
                                                    ESTIMATE_STATUS.INVOICED,
                                                ].includes(
                                                    estimate?.sale_document
                                                        ?.status
                                                )
                                            "
                                            @click="
                                                () => {
                                                    closeDropdown()
                                                    onAccept()
                                                }
                                            "
                                            icon="line-icons:files:file-check-02"
                                        >
                                            {{
                                                $t(
                                                    'estimates.dispatch.actions.markAsAccepted'
                                                )
                                            }}
                                        </base-context-menu-item>

                                        <base-context-menu-item
                                            v-if="
                                                ![
                                                    ESTIMATE_STATUS.DECLINED,
                                                    ESTIMATE_STATUS.INVOICED,
                                                ].includes(
                                                    estimate?.sale_document
                                                        ?.status
                                                )
                                            "
                                            @click="
                                                () => {
                                                    closeDropdown()
                                                    onDecline()
                                                }
                                            "
                                            icon="line-icons:files:file-minus-02"
                                        >
                                            {{
                                                $t(
                                                    'estimates.dispatch.actions.markAsDeclined'
                                                )
                                            }}
                                        </base-context-menu-item>
                                    </template>
                                </template>

                                <base-context-menu-item
                                    icon="line-icons:general:share-07"
                                    @click="onGetShareLink"
                                >
                                    {{
                                        $t(
                                            'estimates.dispatch.actions.getShareLink'
                                        )
                                    }}
                                </base-context-menu-item>

                                <base-context-menu-item
                                    icon="line-icons:general:download-01"
                                    @click="onDownload"
                                >
                                    {{
                                        $t(
                                            'estimates.dispatch.actions.download'
                                        )
                                    }}
                                </base-context-menu-item>

                                <base-context-menu-item
                                    icon="line-icons:general:trash-02"
                                    v-if="
                                        ![ESTIMATE_STATUS.INVOICED].includes(
                                            estimate?.sale_document?.status
                                        ) && $acl.can('delete_estimates')
                                    "
                                    class="border-t"
                                    @click="showDeleteModal = true"
                                >
                                    {{
                                        $t(
                                            'estimates.dispatch.actions.deleteBtn'
                                        )
                                    }}
                                </base-context-menu-item>
                            </template>
                        </base-context-menu>
                    </div>
                </template>
            </base-heading>

            <base-alert
                v-if="!estimate.sale_document?.sent_at"
                class="mx-6 mb-6"
                variant="primary"
                :title="$t('estimates.dispatch.sendEmailNotice.title')"
            >
                <template #description="{ classes }">
                    <div class="flex">
                        <p class="flex-1 text-sm font-normal" :class="classes">
                            {{
                                $t(
                                    'estimates.dispatch.sendEmailNotice.description'
                                )
                            }}
                        </p>

                        <div
                            class="flex flex-1 justify-end gap-4"
                            v-if="$acl.can('update_estimates')"
                        >
                            <base-button @click="sendEstimateModal = true">
                                {{
                                    $t(
                                        'estimates.dispatch.sendEmailNotice.sendBtn'
                                    )
                                }}
                            </base-button>
                            <base-button
                                variant="default"
                                outline
                                :loading="isLoadingMarkAsSent"
                                @click="markAsSent"
                            >
                                {{
                                    $t(
                                        'estimates.dispatch.sendEmailNotice.markAsSentBtn'
                                    )
                                }}
                                <base-icon
                                    name="line-icons:arrows:arrow-right"
                                    variant="inherit"
                                />
                            </base-button>
                        </div>
                    </div>
                </template>
            </base-alert>

            <div class="px-6 pb-6">
                <base-pdf-viewer
                    :content="base64PdfContent"
                    :loading="isLoading"
                />
            </div>
        </div>
    </div>

    <estimates-send-email-modal
        :estimate="estimate"
        :show="sendEstimateModal"
        :loading="isSendingEstimate"
        @modal-close="sendEstimateModal = false"
        @modal-confirm="onSendEstimate"
    />

    <share-link-modal
        v-if="showShareLinkModal"
        :link="shareLink"
        :can-mark-as-sent="!estimate.sale_document?.sent_at"
        @modal-close="showShareLinkModal = false"
        @mark-as-sent="markAsSent"
        :hash="generatedHash"
        type="estimate"
    />

    <estimates-convert-to-invoice-modal
        :show="showConvertToInvoiceModal"
        :loading="isConvertingToInvoice"
        @modal-close="showConvertToInvoiceModal = false"
        @on-submit="convertToInvoice"
    />

    <base-delete-modal
        :show="showDeleteModal"
        :loading="isDeletingEstimate"
        @modal-close="showDeleteModal = false"
        @confirm-delete="onDeleteEstimate"
    >
        <template #description>
            {{ $t('estimates.deleteModal.description') }}
        </template>
    </base-delete-modal>
</template>

<script setup>
import { serialize } from 'object-to-formdata'
import { useApi } from '@tenant/composables'
import { computed } from 'vue'
import { ESTIMATE_STATUS } from '@tenant/modules/tenant/estimates/utils/constants'
import { useEstimate } from '@tenant/modules/tenant/estimates/composables/use-estimate'
import { exportPDF, printDocument } from '@tenant/utils/helper'
import { isNil, omitBy } from 'lodash-es'
import { dayjsFormat } from '@tenant/utils/day'
import {
    DATE_SERVER_FORMAT,
    PAYMENT_TERMS,
    STORAGE,
} from '@tenant/utils/constants'
import { INVOICE_STATUS } from '@tenant/modules/tenant/invoices/utils/constants'
import { useStore } from 'vuex'
import { useTextTemplate } from '@tenant/composables/apis/use-text-template'
import cookie from '@tenant/utils/cookie'

const { getEstimateStatus } = useEstimate()

const route = useRoute()

const estimate = ref(null)

useHead({
    title: computed(() => estimate.value?.estimate_header),
})

watch(
    () => route.params.id,
    async (id) => {
        if (id && route.name === 'estimates.dispatch') {
            await initializeData()
        }
    }
)

// GENERATE AND RENDER PDF
const { execute } = useApi(
    '/api/sale-documents/estimates/{id}/generate-pdf',
    'POST'
)

const base64PdfContent = ref(null)
const isLoading = ref(false)
const { errorNotify, successNotify } = useNotification()
const emitter = useEmitter()
const { t } = useI18n()

onMounted(async () => {
    await initializeData()
})
// END: GENERATE AND RENDER PDF

const initializeData = async () => {
    try {
        emitter.emit('set-loading', true)
        isLoading.value = true

        const result = await execute({
            params: {
                id: route.params.id,
            },
        })

        estimate.value = result.document
        base64PdfContent.value = result.content
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isLoading.value = false
        emitter.emit('set-loading', false)
    }
}

// HANDLE SEND ESTIMATE
const { handleSubmit, setErrors } = useForm({
    initialValues: {
        receiver: [],
    },
})
const sendEstimateModal = ref(false)
const isSendingEstimate = ref(false)
const { execute: executeSendEstimate } = useApi(
    '/api/sale-documents/estimates/{id}/send',
    'POST'
)

const onSendEstimate = handleSubmit((values) => {
    isSendingEstimate.value = true

    return executeSendEstimate({
        params: {
            id: estimate.value.id,
        },
        headers: { 'Content-Type': 'multipart/form-data' },
        data: serialize(values),
    })
        .then(() => {
            initializeData()

            successNotify({
                title: t('estimates.preview.saveDocument.sendSuccess'),
            })

            sendEstimateModal.value = false
        })
        .catch(({ errors }) => {
            setErrors(errors)
        })
        .finally(() => {
            isSendingEstimate.value = false
        })
})
// END: HANDLE SEND ESTIMATE

// HANDLE APPROVE ESTIMATE
const router = useRouter()
const editDraft = () => {
    router.push({
        name: 'estimates.edit',
        params: {
            id: route.params.id,
        },
    })
}
// END: HANDLE APPROVE ESTIMATE

// MARK AS SENT
const { execute: executeMarkAsSent } = useApi(
    '/api/sale-documents/estimates/mark-as-sent',
    'POST'
)

const isLoadingMarkAsSent = ref(false)
const markAsSent = async () => {
    try {
        isLoadingMarkAsSent.value = true

        await executeMarkAsSent({
            data: {
                ids: [route.params.id],
            },
        })

        await initializeData()
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isLoadingMarkAsSent.value = false
    }
}
// END: MARK AS SENT

// GET SHARE LINK
const isGeneratingHash = ref(false)
const showShareLinkModal = ref(false)
const shareLink = ref('')
const generatedHash = ref('')
const onGetShareLink = async () => {
    const { execute } = useApi(
        '/api/sale-documents/estimates/{id}/generate-hash',
        'POST'
    )

    try {
        if (shareLink.value) {
            showShareLinkModal.value = true
            return
        }

        isGeneratingHash.value = true
        const { hash } = await execute({
            params: {
                id: route.params.id,
            },
        })

        generatedHash.value = hash

        isGeneratingHash.value = false
        const shareLinkRoute = router.resolve({
            name: 'estimates.share',
            params: {
                tenantId: cookie.getItem(STORAGE.TENANT_ID),
                hash,
            },
        })

        shareLink.value = `${window.location.origin}${shareLinkRoute.href}`

        showShareLinkModal.value = true
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    }
}
// END: GET SHARE LINK

const onDataUpdate = async (cb) => {
    await initializeData()
    cb()
}

const onDownload = () => {
    exportPDF(`${estimate.value.estimate_header}.pdf`, base64PdfContent.value)
}

// CONVERT TO INVOICE
const showConvertToInvoiceModal = ref(false)
const isConvertingToInvoice = ref(false)
const { execute: executeConvert } = useApi(
    '/api/estimate-convert-to-invoice/{id}',
    'POST'
)

const { execute: generateNumber } = useApi(
    '/api/sequence-settings/generate-number/invoice',
    'GET'
)

const { useApiCreate } = useApiFactory('sale-documents/invoices')
const { execute: saveInvoice } = useApiCreate()

const store = useStore()
const currentUser = computed(() => store.getters.user)

const convertToInvoice = async () => {
    try {
        isConvertingToInvoice.value = true

        await executeConvert({
            params: {
                id: estimate.value.id,
            },
        })

        const { date_of_expiry, date_of_estimate, sale_document } =
            estimate.value
        const { contact } = sale_document
        const invoiceNumber = await generateNumber()

        const { id: invoiceId } = await saveInvoice({
            ...omitBy(sale_document, isNil),
            number: invoiceNumber,
            invoice_header: t('createInvoice.invoiceHeaderPlaceholderValue', {
                number: invoiceNumber,
            }),
            contact_id: contact.contact_id,
            user_id: currentUser.value.id,
            due_date: dayjsFormat(date_of_expiry, DATE_SERVER_FORMAT),
            date_of_invoice: dayjsFormat(date_of_estimate, DATE_SERVER_FORMAT),
            date_of_delivery: dayjsFormat(date_of_estimate, DATE_SERVER_FORMAT),
            status: INVOICE_STATUS.DRAFT,
            header_content: headerContent.value,
            footer_content: footerContent.value,
            payment_term: PAYMENT_TERMS.CUSTOM,
        })

        successNotify({
            title: t('estimates.dispatch.convert.successMsg'),
        })

        await router.push({
            name: 'invoices.dispatch',
            params: { id: invoiceId },
        })
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isConvertingToInvoice.value = false
        showConvertToInvoiceModal.value = false
    }
}

const { getDefaultTextTemplate } = useTextTemplate()
const textTemplates = ref(null)

const footerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'footer_content'
        )?.content ?? ''
    )
})

const headerContent = computed(() => {
    return (
        textTemplates.value?.find(
            (template) => template.line_item === 'header_content'
        )?.content ?? ''
    )
})

const isLoadingTextTemplates = ref(false)
const openCovertInvoiceModal = async () => {
    try {
        isLoadingTextTemplates.value = true

        textTemplates.value = await getDefaultTextTemplate(
            'document',
            'invoices'
        )

        showConvertToInvoiceModal.value = true
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        isLoadingTextTemplates.value = false
    }
}
// END: CONVERT TO INVOICE

// ACCEPT ESTIMATE
const { execute: executeAccept } = useApi('/api/estimates/{id}/accept', 'POST')

const onAccept = async () => {
    try {
        emitter.emit('set-loading', true)

        await executeAccept({
            params: {
                id: estimate.value.id,
            },
        })

        successNotify({
            title: t('estimates.dispatch.accept.successMsg'),
        })

        await initializeData()
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: ACCEPT ESTIMATE

// DECLINE ESTIMATE
const { execute: executeDecline } = useApi(
    '/api/estimates/{id}/decline',
    'POST'
)

const onDecline = async () => {
    try {
        emitter.emit('set-loading', true)

        await executeDecline({
            params: {
                id: estimate.value.id,
            },
        })

        successNotify({
            title: t('estimates.dispatch.decline.successMsg'),
        })

        await initializeData()
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    } finally {
        emitter.emit('set-loading', false)
    }
}
// END: DECLINE ESTIMATE

// DELETE ESTIMATE
const { execute: executeDelete, loading: isDeletingEstimate } = useApi(
    '/api/sale-documents/estimates/{id}',
    'DELETE'
)

const showDeleteModal = ref(false)
const onDeleteEstimate = async () => {
    try {
        showDeleteModal.value = false
        await executeDelete({
            params: {
                id: estimate.value.id,
            },
        })

        await router.push({
            name: 'estimates.list',
        })

        successNotify({
            title: t('estimates.deleteModal.successMsg'),
        })
    } catch ({ errorMessage }) {
        errorNotify({
            title: errorMessage,
        })
    }
}
// END: DELETE ESTIMATE
</script>

<style lang="scss">
.estimate-view {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
        0px 1px 3px 0px rgba(16, 24, 40, 0.1);

    .estimate-container {
        padding: 50px 35px 25px 35px;
    }

    .estimate-footer {
        position: relative;
        margin-top: 158px;
        padding: 0;
    }
}
</style>
