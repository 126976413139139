<template>
    <base-button-group>
        <base-button
            variant="default"
            :class="{
                'bg-gray-100': !status,
            }"
            @click="viewChange()"
        >
            {{ $t('estimates.list.viewAll') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(ESTIMATE_STATUS.DRAFT)"
            :class="{
                'bg-gray-100': hasStatus(ESTIMATE_STATUS.DRAFT),
            }"
        >
            {{ $t('estimates.list.draft') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(ESTIMATE_STATUS.SENT)"
            :class="{
                'bg-gray-100': hasStatus(ESTIMATE_STATUS.SENT),
            }"
        >
            {{ $t('estimates.list.sent') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(ESTIMATE_STATUS.EXPIRED)"
            :class="{
                'bg-gray-100': hasStatus(ESTIMATE_STATUS.EXPIRED),
            }"
        >
            {{ $t('estimates.list.expired') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(ESTIMATE_STATUS.ACCEPTED)"
            :class="{
                'bg-gray-100': hasStatus(ESTIMATE_STATUS.ACCEPTED),
            }"
        >
            {{ $t('estimates.list.accepted') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(ESTIMATE_STATUS.DECLINED)"
            :class="{
                'bg-gray-100': hasStatus(ESTIMATE_STATUS.DECLINED),
            }"
        >
            {{ $t('estimates.list.declined') }}
        </base-button>
        <base-button
            variant="default"
            @click="viewChange(ESTIMATE_STATUS.INVOICED)"
            :class="{
                'bg-gray-100': hasStatus(ESTIMATE_STATUS.INVOICED),
            }"
        >
            {{ $t('estimates.list.invoiced') }}
        </base-button>
    </base-button-group>
</template>

<script setup>
import { useFilters } from '@tenant/composables'
import { ESTIMATE_STATUS } from '@tenant/modules/tenant/estimates/utils/constants'
import { dayjsFormat, now } from '@tenant/utils/day'
import { DATE_SERVER_FORMAT } from '@tenant/utils/constants'

const queries = {
    type: 'filter.saleDocument.status',
    date_of_expiry: 'filter.date_of_expiry',
}
const { filterValues, updateFilters } = useFilters(queries)

const status = ref(null)

onMounted(() => {
    initStatus()
})

const initStatus = () => {
    const { type } = filterValues.value
    if (!type) {
        return
    }

    if (Array.isArray(type) && type.length > 0) {
        const statuses = type[1] || []

        if (statuses.includes(ESTIMATE_STATUS.SENT)) {
            status.value = ESTIMATE_STATUS.EXPIRED

            return
        }
    }

    status.value = type
}

const viewChange = (updateStatus = null) => {
    const filters = {
        [queries.type]: updateStatus,
        [queries.date_of_expiry]: null,
    }

    if (updateStatus === ESTIMATE_STATUS.EXPIRED) {
        filters[queries.type] = ['IN', [ESTIMATE_STATUS.SENT]]
        filters[queries.date_of_expiry] = [
            'DATE <',
            dayjsFormat(now(), DATE_SERVER_FORMAT),
        ]
    }

    status.value = updateStatus
    updateFilters(filters)
}

const hasStatus = (value) => {
    return status.value === value
}
</script>
