export default {
    editEstimate: {
        title: 'Edit estimate',
    },
    createEstimate: {
        title: 'New estimate',
        description:
            'Manage your team members and their account permissions here.',
        preview: 'Preview',
        saveAsDraft: 'Save as draft',
        proceedBtn: 'Save and continue',
        customerInfoTitle: 'Content and estimate information',
        customerLabel: 'Customer *',
        customerPlaceholder: 'Search / create contact',
        customerAddressLabel: 'Address',
        customerAddressPlaceholder: 'Enter an address...',
        customerCountryLabel: 'Country *',
        estimateHeaderLabel: 'Estimate header *',
        estimateHeaderPlaceholderValue: 'Estimate no. {number}',
        estimateHeaderPlaceholder: 'Estimate no. RE-1020',
        estimateNumberLabel: 'Estimate number *',
        estimateNumberPlaceholder: 'RE-1020',
        dateOfEstimateLabel: 'Date of estimate *',
        dateOfInvoiceLabel: 'Date of invoice *',
        dueDateOfEstimateLabel: 'Due date',
        dateOfExpiryLabel: 'Valid until',
        estimateDateOfDeliveryLabel: 'Date of delivery',
        estimateReferenceNoLabel: 'Reference / Order No.',
        estimateSameDateToggleLabel: 'Deliver date like estimate date?',
        headerContentTitle: 'Header content',
        footerContentTitle: 'Footer content',
        lineItemsTitle: 'Products and items',
        moreOptionsLabel: 'More options',
        currencyLabel: 'Currency',
        cashDiscountLabel: 'Cash discount',
        cashDiscountSuffix: ' Days',
        cashDiscountPlaceholder: '0 Days',
        cashDiscountPercentsLabel: 'Percents',
        cashDiscountPercentsSuffix: ' %',
        cashDiscountPercentsPlaceholder: '0 %',
        contactPersonLabel: 'Internal contact person',
        vatRuleTitle: 'VAT RULE',
        vatRuleFirst: 'Identify VAT code',
        vatRuleSecond: 'Tax free intra-community delivery (EU)',
        vatRuleThird:
            'Tax obligation of the beneficiary (outside the EU, e.g. Switzerland)',
        netAmount: 'Net amount (inc. discount/surcharge)',
        vat: 'VAT {n}%',
        total: 'Total',
        createNewContactBtn: 'Create new contact',
        customerNo: 'Cust No. {number}',
    },
    estimates: {
        title: 'Estimates',
        description: 'Sum of unpaid amounts: {sum}',
        emptyText: 'No estimates found',
        emptySubText: 'Go ahead an create your first estimate',
        numberOfEstimates: 'no Estimates | 1 Estimate | {count} Estimates',

        general: {
            saveSuccess: 'Estimate successfully created!',
            copySuccess: 'Estimate successfully copied!',
            saveAsDraftSuccess: 'Estimate successfully saved as draft!',
        },

        sidebar: {
            label: 'Estimates',
        },

        list: {
            add: 'Add estimate',
            status: 'Status',
            dueDate: 'Due date',
            dateOfExpiry: 'Valid until',
            number: 'No.',
            customer: 'Customer / Estimate header',
            date: 'Date',
            amountNet: 'Amount (Net)',
            amountGross: 'Amount (Gross)',
            unpaidGross: 'Unpaid amount (Gross)',
            viewAll: 'View All',
            draft: 'Draft',
            unpaid: 'Unpaid',
            due: 'Due',
            paid: 'Paid',
            partPaid: 'Part-paid',
            expired: 'Expired',
            declined: 'Declined',
            accepted: 'Accepted',
            invoiced: 'Invoiced',
            sent: 'Sent',
            searchByEstimateNumber: 'Search by estimate number',
            contextMenu: {
                send: 'Send',
                download: 'Download',
                preview: 'Preview',
                addTask: 'Add task',
                addDelivery: 'Add delivery note',
                copyEstimate: 'Copy estimate',
                paymentReminder: 'Payment reminder',
                editDocument: 'Edit document',
                markAsUnpaid: 'Mark as unpaid',
                receivedInfo: 'Sent payment received information',
                cancelEstimate: 'Cancel estimate',
            },
            filters: {
                netAmount: 'Amount (net)',
            },
        },
        preview: {
            title: 'Preview document as draft',
            headline: 'Preview document as draft',
            print: 'Print',
            download: 'Download',
            saveAsDraft: 'Save as draft',
            settings: {
                headline: 'Settings',
                language: {
                    label: 'Language',
                    placeholder: 'Language',
                },
                template: {
                    label: 'Template',
                    placeholder: 'Template',
                },
                letterhead: {
                    label: 'Letterhead',
                    placeholder: 'Letterhead',
                    create: 'Create letterpaper',
                },
                qrCode: {
                    label: 'QR code',
                },
                productNumber: {
                    label: 'Product number',
                },
                taxByLineItems: {
                    label: 'Tax by line item',
                },
                foldlines: {
                    label: 'Foldlines',
                },
            },
            saveDocument: {
                headline: 'Save document',
                saveAsDraft: 'Save as draft',
                save: 'Save document',
                sendSuccess: 'Estimate successfully sent!',
                saveSuccess: 'Estimate successfully created!',
                saveAsDraftSuccess: 'Estimate successfully saved as draft!',
                warningModal: {
                    title: 'Estimate is fixed after dispatch',
                    description:
                        'According to GoD, outgoing estimates may no longer be changeable after dispatch to the customer.' +
                        '<br><br>' +
                        'In the settings the fixing can be deactivated by default.' +
                        '<br><br>' +
                        'GoBD = Principles for the proper keeping and safekeeping of books, records and documents in electronic form as well as for data access.',
                },
            },
            sendDocument: {
                headline: 'Send document',
                receiver: {
                    label: 'Receiver *',
                    placeholder: 'Receiver',
                },
                cc: {
                    label: 'CC:',
                    placeholder: "cc{'@'}example.com",
                },
                bcc: {
                    label: 'BCC:',
                    placeholder: "bcc{'@'}example.com",
                },
                subject: {
                    label: 'Subject *',
                    placeholder: 'Estimate no. RE-1020',
                },
                message: {
                    label: 'Message',
                },
                signature: {
                    label: 'Signature',
                },
                attachment: {
                    label: 'Attachment',
                    description: 'SVG, PNG, JPG or GIF (max. 800x400px)',
                },
                sendByEmail: 'Send by email',
                buttonsDeviderText: 'Or send by post',
                sendLetter: 'Send letter for €1.75',
                mailingSmallText:
                    'Utilize our mailing api to save up to 50% processing cost',
            },
        },
        save: {
            title: 'Save document',
            headline: 'Save document',
            saveDocument: 'Save document',
        },
        dispatch: {
            title: 'Estimate no. RE-1031',
            headline: 'Estimate no. RE-1031',
            dueHeadline: 'Due',
            overview: {
                status: 'Status',
                customer: 'Customer',
                estimateTotal: 'Estimate total',
                validUntil: 'Valid until',
            },
            actions: {
                moreActions: 'More actions',
                sendDocument: 'Send document',
                addTask: 'Add task',
                addDeliveryNote: 'Add delivery note',
                copyEstimate: 'Copy estimate',
                download: 'Download',
                editDocument: 'Edit document',
                cancelEstimate: 'Cancel estimate',
                exportPdf: 'Export as PDF',
                print: 'Print',
                cancel: 'Cancel',
                createAnotherEstimate: 'Create another estimate',
                edit: 'Edit',
                send: 'Send',
                resend: 'Resend',
                getShareLink: 'Get share link',
                markAsAccepted: 'Mark as accepted',
                markAsDeclined: 'Mark as declined',
                markAsSent: 'Mark as sent',
                deleteBtn: 'Delete',
                convert: 'Convert to invoice',
            },
            netAmount: {
                label: 'Amount (Net)',
            },
            grossAmount: {
                label: 'Amount (Gross)',
            },
            unpaidGrossAmount: {
                label: 'Unpaid amount (Gross)',
            },
            customer: {
                label: 'Customer',
            },
            dueDate1: {
                label: 'Due date',
            },
            dueDate2: {
                label: 'Due date',
            },
            sentOn: {
                label: 'Sent on',
            },
            enshrined: {
                label: 'Enshrined',
            },
            tags: {
                label: 'Tags',
            },
            today: 'Today',
            estimateCancel: {
                title: 'Cancel estimate',
                description:
                    'This process creates a cancellation estimate directly. It is then no longer possible to process the canceled outgoing estimate. Are you sure you want to cancel the estimate?',
                successMessage: 'Estimate has been cancelled',
            },
            draftNotice:
                'This is a DRAFT estimate. You can take further actions once you approve it.',
            createCard: {
                title: 'Create',
                createdLabel: 'Created: ',
                approveDraft: 'Approve draft',
                editDraft: 'Edit draft',
            },
            sendCard: {
                title: 'Send',
                lastSentLabel: 'Last sent: ',
                sendEstimate: 'Send estimate',
                resendEstimate: 'Resend estimate',
                neverText: 'Never',
                orText: 'or',
                markAsSent: 'mark as sent',
                getShareLink: 'Get share link',
            },
            convertCard: {
                title: 'Convert to invoice',
                estimateTotal: 'Estimate total: ',
                convertButtonText: 'Convert to invoice',
            },
            transactions: {
                description:
                    '{date} - A payment for {amount} was made using a bank payment.',
            },
            sidebar: {
                filter: {
                    allEstimates: 'All estimates',
                },
                markAsSentBtn: 'Mark as sent',
                deleteBtn: 'Delete',
                selectedText: '{n} selected',
            },
            sendEmailNotice: {
                title: 'Send the estimate',
                description:
                    'Go ahead and email this estimate to your customer or simply mark it as sent.',
                sendBtn: 'Send estimate',
                markAsSentBtn: 'Mark as sent',
            },
            convert: {
                title: 'Convert to invoice',
                description:
                    'Do you really want to convert this estimate into an invoice?',
                successMsg: 'Estimate successfully converted to invoice!',
            },
            accept: {
                successMsg: 'Estimate successfully accepted!',
            },
            decline: {
                successMsg: 'Estimate successfully declined!',
            },
            markAsSentModal: {
                title: 'Mark estimates as sent',
                description:
                    'All selected estimates will be marked as sent. Are you sure you want to continue?',
                confirmBtn: 'Yes, mark as sent',
                successMsg: 'Estimates marked as sent successfully',
            },
        },
        deleteModal: {
            title: 'Delete estimate',
            description:
                'Once you choose to delete, the details of the estimate will be removed from the system forever and you will not be able to retrieve it later. Are you sure about deleting this estimate?',
            successMsg: 'Estimate has been deleted',
        },
    },
}
